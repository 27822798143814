<template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      item-key="id"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
            </template>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(login)">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <ValidationProvider vid="name" name="Nombre de Usuario" rules="required" v-slot="{ errors, valid }">
                            <v-text-field
                              autofocus
                              filled
                              v-model="item.name"
                              label="Nombre de Usuario"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ValidationProvider
                            vid="password"
                            name="Contraseña"
                            :rules="item.id ? '' : 'required|min:8'"
                            v-slot="{ errors, valid }"
                          >
                            <v-text-field
                              type="password"
                              autocomplete="new-password"
                              filled
                              v-model="item.password"
                              label="Contraseña"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors, valid }">
                            <v-text-field
                              type="email"
                              filled
                              v-model="item.email"
                              label="E-mail"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ValidationProvider vid="roles" name="Nivel de Usuario" rules="required" v-slot="{ errors, valid }">
                            <v-select
                              v-model="item.roles"
                              :items="roles"
                              item-value="key"
                              item-text="value"
                              filled
                              label="Nivel de Usuario"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <!-- Nota: Temporalmente permitir asignar un médico a un admin para que reciba notificaciones -->
                      <v-row v-show="['ROLE_MEDICO', 'ROLE_ADMIN'].includes(item.roles)">
                        <v-col>
                          <ValidationProvider vid="medico_id" name="Médico" rules="" v-slot="{ errors, valid }">
                            <v-select
                              v-model="item.medico_id"
                              :items="medicos"
                              item-value="id"
                              item-text="nombre"
                              filled
                              dense
                              label="Médico"
                              :error-messages="errors"
                              :success="valid"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row v-show="item.roles === 'ROLE_USER'">
                        <v-col>
                          <ValidationProvider vid="paciente_id" name="Paciente" rules="" v-slot="{ errors, valid }">
                            <v-autocomplete
                              filled
                              v-model="item.paciente_id"
                              hide-details
                              :loading="isLoadingPacientes"
                              :items="pacientes"
                              :search-input.sync="searchPacientes"
                              no-filter
                              clearable
                              required
                              label="Paciente"
                              class="mb-2"
                              placeholder="Buscar Paciente"
                              item-value="id"
                              item-text="nombres"
                              @change="setPaciente"
                              return-object
                              :error-messages="errors"
                              :success="valid"
                              no-data-text="No hay resultados"
                            >
                              <template v-slot:selection="{ item }">
                                {{ [item.apellidos, item.nombres].join(', ') }}
                              </template>
                              <template v-slot:item="{ item }">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ [item.apellidos, item.nombres].join(', ') }}
                                  </v-list-item-title>
                                  <v-list-item-subtitle>
                                    <span class="text--primary">
                                      {{ item.doc_tipo_descripcion }} {{ item.doc_numero }}
                                    </span>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="saving" @click="close">Cancelar</v-btn>
                    <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                        dark :loading="saving">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </ValidationObserver>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.roles="{ item }">
        {{ roles.find(r => r.key === item.roles).value }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import _ from 'lodash'
//import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    dialog: false,
    loading: false,
    isLoadingPacientes: false,
    saving: false,
    search: '',
    headers: [
      { text: 'Nombre de Usuario', value: 'name' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Rol', value: 'roles' },
      { text: 'Médico', value: 'medico_nombre' },
      { text: 'Paciente', value: 'paciente_descripcion' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    item: {
      name: '',
      email: '',
      password: '',
      roles: 'ROLE_USER',
      medico_id: null,
      paciente_id: null,
    },
    defaultItem: {
      name: '',
      email: '',
      password: '',
      roles: 'ROLE_USER',
      medico_id: null,
      paciente_id: null,
    },
    medicos: [],
    roles: [
      {
        key: 'ROLE_USER',
        value: 'Usuario',
      },
      {
        key: 'ROLE_MEDICO',
        value: 'Médico',
      },
      {
        key: 'ROLE_ADMIN',
        value: 'Administrador',
      }
    ],
    pacientes: [],
    searchPacientes: '',
  }),

  computed: {
    formTitle () {
      return (typeof this.item.id === 'undefined') ? 'Nuevo' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function (/*val*/) {
      this.debounceRefresh()
    },

    searchPacientes (val) {
      if (val && val.length > 1) {
        this.fetchPacientes(val);
        return
      }

      if ((this.pacientes.length === 1) && (this.pacientes[0].id === this.item.paciente_id)) {
        // TODO: Mejorar parche para que queden los datos del paciente del usuario a editar
        return
      }

      this.pacientes = [];
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true;

      let filters = {
        filter: this.search,
        sortBy: this.options.sortBy[0] || null,
        descending: this.options.sortDesc[0] || null,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('usuarios', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    save: function () {
      this.saving = true

      if (typeof this.item.id === 'undefined') {
        this.create()
      } else {
        this.update()
      }
    },

    create: function () {
      this.$http.post('usuarios', this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!', 'success')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    update: function () {
        this.$http.put('usuarios/'+this.item.id, this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!', 'success')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    editItem: function (item) {
      this.$http.get('usuarios/'+item.id)
        .then((response) => {
          this.item = response.data

          if (!item.paciente_id) {
            this.dialog = true
          }

          this.fetchPaciente(item.paciente_id).then(() => {
            this.dialog = true
          })
        })
        .catch((error) => {
          alert(error);
        })
    },

    deleteItem: function (item) {
      if ( confirm('Está usted seguro que quiere eliminar este registro?') ) {
        this.$http.delete('usuarios/'+item.id)
          .then(() => {
            this.query();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 422:
                this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')
                break;
              default:
                this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
            }
          })
      }
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    close: function () {
      this.resetForm()
      this.searchPacientes = ''
      this.dialog = false
    },

    fetchMedicos: function () {
      this.$http.get('medicos')
      .then((response) => {
        this.medicos = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    fetchPaciente(id) {
      this.isLoadingPacientes = true;

      return this.$http.get(`pacientes/${id}`)
      .then((response) => {
        this.pacientes = [response.data]
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error, 'error') })
      .then(() => {
        this.isLoadingPacientes = false;
      })
    },

    fetchPacientes(val = '') {
      this.isLoadingPacientes = true;

      let filters = {
        filter: val,
      };

      this.$http.get('pacientes', {params: filters})
      .then((response) => {
        this.pacientes = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.isLoadingPacientes = false;
      })
    },

    setPaciente (selected) {
      if (selected) {
        this.item.paciente_id = selected.id
      }
    },
  },

  created () {
    this.fetchMedicos()
  }
};
</script>
