var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Usuarios")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.login)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"name","name":"Nombre de Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"autofocus":"","filled":"","label":"Nombre de Usuario","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"password","name":"Contraseña","rules":_vm.item.id ? '' : 'required|min:8'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"new-password","filled":"","label":"Contraseña","error-messages":errors,"success":valid},model:{value:(_vm.item.password),callback:function ($$v) {_vm.$set(_vm.item, "password", $$v)},expression:"item.password"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"email","name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","filled":"","label":"E-mail","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"roles","name":"Nivel de Usuario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.roles,"item-value":"key","item-text":"value","filled":"","label":"Nivel de Usuario","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.roles),callback:function ($$v) {_vm.$set(_vm.item, "roles", $$v)},expression:"item.roles"}})]}}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(['ROLE_MEDICO', 'ROLE_ADMIN'].includes(_vm.item.roles)),expression:"['ROLE_MEDICO', 'ROLE_ADMIN'].includes(item.roles)"}]},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"medico_id","name":"Médico","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.medicos,"item-value":"id","item-text":"nombre","filled":"","dense":"","label":"Médico","error-messages":errors,"success":valid},model:{value:(_vm.item.medico_id),callback:function ($$v) {_vm.$set(_vm.item, "medico_id", $$v)},expression:"item.medico_id"}})]}}],null,true)})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.roles === 'ROLE_USER'),expression:"item.roles === 'ROLE_USER'"}]},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"paciente_id","name":"Paciente","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{staticClass:"mb-2",attrs:{"filled":"","hide-details":"","loading":_vm.isLoadingPacientes,"items":_vm.pacientes,"search-input":_vm.searchPacientes,"no-filter":"","clearable":"","required":"","label":"Paciente","placeholder":"Buscar Paciente","item-value":"id","item-text":"nombres","return-object":"","error-messages":errors,"success":valid,"no-data-text":"No hay resultados"},on:{"update:searchInput":function($event){_vm.searchPacientes=$event},"update:search-input":function($event){_vm.searchPacientes=$event},"change":_vm.setPaciente},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s([item.apellidos, item.nombres].join(', '))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s([item.apellidos, item.nombres].join(', '))+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.doc_tipo_descripcion)+" "+_vm._s(item.doc_numero)+" ")])])],1)]}}],null,true),model:{value:(_vm.item.paciente_id),callback:function ($$v) {_vm.$set(_vm.item, "paciente_id", $$v)},expression:"item.paciente_id"}})]}}],null,true)})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.saving},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return passes(_vm.save)}}},[_vm._v("Guardar")])],1)],1)],1)]}}])})],1)],1)]},proxy:true},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles.find(function (r) { return r.key === item.roles; }).value)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }